<template>
  <div>
    <RoundQuickJump
      class="round-quick-jump"
      v-if="startedScroll"
      @block-select="selectNewsBlock"
    />
    <div class="news-wrap" ref="newsWrap">
      <div class="news">
        <div class="news__title-section">
          <h2 class="news__title">{{ $lui('navigation.news') }}</h2>

          <QuickJump v-if="!startedScroll" @block-select="selectNewsBlock" />
        </div>
        <div v-for="(news, i) in getNews()" :key="i">
          <div v-if="news.info && news.info.length > 0" ref="newsBlock">
            <div class="news__turns-info-wrap">
              <div class="news__turns-info-text">
                <p class="news__turn news__turn_left-title">
                  {{ $lui('news.step') + ' ' + (i + 1) }}
                </p>
                <p class="news__turn_right-title">{{ $lui('news.info') }}</p>
              </div>
            </div>

            <div v-for="(card, i) in news.info" :key="i">
              <CardNews :item="card" />
            </div>
          </div>
          <div v-if="news.results && news.results.length > 0" ref="newsBlock">
            <div class="news__turns-results-wrap">
              <div class="news__turns-results-text">
                <p class="news__result news__turn_left-title">
                  {{ $lui('news.step') + ' ' + (i + 1) }}
                </p>
                <p class="news__turn_right-title">{{ $lui('news.results') }}</p>
              </div>
            </div>
            <div v-for="(card, i) in news.results" :key="i">
              <CardNews :item="card" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuickJump from '../QuickJump.vue'
import RoundQuickJump from '../RoundQuickJump.vue'
import CardNews from './CardNews.vue'

export default {
  components: {
    QuickJump,
    CardNews,
    RoundQuickJump
  },

  data() {
    return {
      startedScroll: false
    }
  },

  computed: mapGetters({
    getNewNews: 'session/getNewNews'
  }),

  methods: {
    scrollToDown() {
      setTimeout(() => {
        let lastId = this.$refs.newsBlock.length - 1
        window.scrollTo(
          0,
          this.$refs.newsBlock[lastId].getBoundingClientRect().y -
            this.$refs.newsWrap.getBoundingClientRect().y
        )
      }, 100)
    },

    selectNewsBlock(id) {
      if (this.$refs.newsBlock.length > id) {
        window.scrollTo(
          0,
          this.$refs.newsBlock[id].getBoundingClientRect().y -
            this.$refs.newsWrap.getBoundingClientRect().y
        )
      }
    },

    getNews() {
      return this.getNewNews
    },

    scrollHandle(e) {
      var top = e.srcElement.scrollingElement.scrollTop
      if (top >= 300) {
        this.startedScroll = true
      } else if (top <= 100) {
        this.startedScroll = false
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', this.scrollHandle)

    if (this.$refs.newsBlock && this.$refs.newsBlock.length > 1) {
      this.scrollToDown()
    }

    //this.$store.subscribe()
  }
}
</script>

<style lang="scss" scoped>
.news__turn_left-title {
  width: 13%;
  text-align: center;
  left: 37.7%;
  position: absolute;
}

.news__turn_right-title {
  width: 12.7%;
  text-align: center;
  left: 51%;
  position: absolute;
}

.round-quick-jump {
  position: fixed;
  top: 80px;
  width: 100%;
}

.margin-bottom-box {
  width: 100%;
  height: 100px;
}

.news-wrap {
  padding-bottom: 115px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-46%);

  &::-webkit-scrollbar {
    width: 0;
  }
}

.news {
  position: relative;
  width: 58.57vw;
  padding: 0.838vw 0.966vw 0.773vw;
  background-image: url(../../assets/news/news-bg.png);
  background-repeat: no-repeat;
  background-size: 58.57vw 100%;
  scroll-behavior: smooth;

  @media screen and (min-width: 1024px) and (max-width: 1551px) {
    padding: 1.016vw 1.25vw 1.016vw;
    width: 70.898vw;
    background-size: 70.898vw 100%;
  }

  &__title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-left: 1.675vw;
    font-style: normal;
    font-weight: bold;
    font-size: 2.706vw;
    line-height: 5.155vw;
    color: #ffffff;
    text-shadow: -1px 1px 0px #000000;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      font-size: 3.281vw;
      line-height: 6.25vw;
    }
  }

  &__quick-jump-btn {
    display: block;
    margin-left: auto;
    padding: 0;
    margin-right: 9px;
    background-image: url(../../assets/news/quickJumpBtnBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #000000;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  &__quick-jump-btn-content {
    display: flex;
    justify-content: center;
  }

  &__turns-info-wrap,
  &__turns-results-wrap {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 1.031vw;
    line-height: 1.224vw;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 1.031vw;
    width: 57vw;
    height: 100px;
    background-image: url(../../assets/news/turnsInfoLine.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1600px) and (max-width: 2000px) {
      height: 50px;
    }

    @media screen and (min-width: 2000px) and (max-width: 2400px) {
      height: 60px;
    }

    @media screen and (min-width: 2400px) and (max-width: 2500px) {
      height: 70px;
    }

    @media screen and (min-width: 2500px) and (max-width: 3000px) {
      height: 80px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1552px) {
      width: 70vw;
      height: 60px;
      font-size: 1.5vw;
      line-height: 1.758vw;
      margin-left: -1vw;
    }

    @media screen and (min-width: 1552px) and (max-width: 1600px) {
      width: 58.5vw;
      height: 60px;
      font-size: 1.5vw;
      line-height: 1.758vw;
      margin-left: -1vw;
    }

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      width: 70vw;
      height: 40px;
      font-size: 1.5vw;
      line-height: 1.758vw;
      margin-left: -1vw;
    }

    @media screen and (min-width: 600px) and (max-width: 1024px) {
      width: 58.5vw;
      height: 36px;
      font-size: 1.5vw;
      line-height: 1.758vw;
      margin-left: -1vw;
    }

    @media screen and (max-width: 600px) {
      height: 20px;
    }
  }

  &__turns-results-wrap {
    margin-top: 2.642vw;
    margin-bottom: 1.546vw;
    background-image: url(../../assets/news/turnsResultsLine.png);
  }

  &__turns-info-text,
  &__turns-results-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.835vw 0 2.191vw;
    width: 15.206vw;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      padding: 0 1.102vw 0 0.125vw;
    }
  }

  &__turns-results-text {
    padding: 0 26px 0 34px;

    @media screen and (min-width: 1024px) and (max-width: 1551px) {
      justify-content: space-between;
      padding: 0;
      width: 16.206vw;
    }
  }
}

.arrow-down {
  margin-left: 8px;
}

.card {
  cursor: auto;
}
</style>
